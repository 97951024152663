import React, { useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import UserModal from './UserModal';
import ExportExcelButton from 'components/common/ExportExcelButton';
import UserService from 'http/UserService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import moment from 'moment';
import { defaultDateFormat } from 'helpers/utils';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 10
};

const UserListTableHeader = ({
  handleShow,
  t,
  user,
  roles,
  refetch,
  filters,
  setFilters,
  sortInfo,
  tableColumns,
  totalCount,
  setUserToEdit
}) => {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  const [headersToExport, setHeadersToExport] = useState([]);
  useEffect(() => {
    if (!!user) {
      setUserModalOpen(true);
    }
  }, [user]);

  const handleExport = () => {
    setLoadingExport(true);
    UserService.list({
      ...filters,
      ...sortInfo,
      pageNumber: 1,
      pageSize: totalCount
    })
      .then(response => {
        if (response?.data?.length) {
          flattenExcelData(response?.data, tableColumns);
        } else {
          toast.warning(t('common:message.noDataToExport'), {
            theme: 'colored'
          });
        }
        setLoadingExport(false);
      })
      .catch(error => {
        console.log('errorUserService.list export', error);
        toast.error(getErrorMessage(t, error), { theme: 'colored' });
        setLoadingExport(false);
      });
  };

  const flattenExcelData = (data, headers) => {
    const keys = headers
      .filter(h => h.exportable !== false)
      .map(h => h.accessor);
    setHeadersToExport(
      headers.filter(h => h.exportable !== false).map(h => h.Header)
    );
    let rows = data.map(row => {
      let arr = [];
      keys.map(key => {
        if (key === 'roleNames') {
          arr.push(row.roleNames?.map(r => r.name).join(',') || '');
        } else if (key === 'metadata.createdAt') {
          arr.push(
            `${(row['metadata'] || {})['createdAt'] || ''}`
              ? moment(`${(row['metadata'] || {})['createdAt']}`).format(
                  defaultDateFormat
                )
              : ''
          );
        } else {
          arr.push(`${row[key]}`);
        }
      });
      return arr;
    });
    //console.log('rows', rows);
    setDataToExport(rows);
  };

  const handleSearch = term => {
    //console.log('handleSearch term', term);
    let searchPayload = {
      query: {
        term: term,
        conjunction: 'or',
        properties: ['FirstName', 'LastName', 'Email', 'DisplayName']
      }
    };
    if (!term) {
      searchPayload = {
        query: null
      };
    }
    setFilters({
      ...filters,
      ...defaultPaging,
      ...searchPayload
    });
  };

  const handleCloseUserModal = () => {
    setUserModalOpen(false);
    setUserToEdit(null);
  };

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1 col-12 col-md-6 flex-md-nowrap">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">{t('user:allUsers')}</h6>
        </Col>
        <Col xs="12">
          <AdvanceTableSearchBox
            //className="input-search-width"
            globalFilter={''}
            setGlobalFilter={handleSearch}
            placeholder={t('user:placeholders.searchPlaceholder')}
          />
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        <IconButton
          variant="cubeNet-default"
          size="sm"
          icon="filter"
          transform="shrink-4"
          iconAlign="middle"
          onClick={handleShow}
          className="d-xl-none"
        >
          <span className="d-none d-sm-inline-block ms-1">
            {t('common:button.filter')}
          </span>
        </IconButton>
        <div
          className="bg-300 mx-3 d-none d-lg-block d-xl-none"
          style={{ width: '1px', height: '29px' }}
        ></div>
        <div id="orders-actions">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="user-newUser-tooltip">
                {t('user:button.newUser')}
              </Tooltip>
            }
          >
            <span>
              <IconButton
                variant="cubeNet-default"
                size="sm"
                icon="plus"
                //transform="shrink-3"
                iconAlign="middle"
                onClick={() => setUserModalOpen(true)}
              >
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                  {t('user:button.newUser')}
                </span>
              </IconButton>
            </span>
          </OverlayTrigger>
          <ExportExcelButton
            t={t}
            loading={loadingExport}
            data={dataToExport}
            headers={headersToExport}
            onClick={handleExport}
          />
        </div>
      </div>
      {userModalOpen && (
        <UserModal
          isOpen={userModalOpen}
          onClose={() => handleCloseUserModal()}
          user={user}
          refetch={refetch}
          roles={roles}
        />
      )}
    </div>
  );
};

UserListTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  handleShow: PropTypes.func,
  layout: PropTypes.string,
  selectedItems: PropTypes.array,
  handleTicketsSearch: PropTypes.func
};

export default UserListTableHeader;
