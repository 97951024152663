import CubeNetCloseButton from 'components/common/CubeNetCloseButton';
import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  header,
  loading,
  actionButtonColor,
  actionButtonText
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={isOpen}
      onHide={() => onClose(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ zIndex: 9999 }}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {header || ''}
        </Modal.Title>
        <CubeNetCloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => onClose(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <p>{message || ''}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => onConfirm()}
          variant={actionButtonColor || 'primary'}
          disabled={loading}
        >
          {!loading && (actionButtonText || t('common:button.confirm'))}
          {loading && <Spinner animation="border" role="status" size="sm" />}
        </Button>
        <Button
          onClick={() => onClose(false)}
          variant="secondary"
          disabled={loading}
        >
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  message: PropTypes.string,
  header: PropTypes.string,
  actionButtonColor: PropTypes.string,
  actionButtonText: PropTypes.string,
  loading: PropTypes.bool
};

export default ConfirmModal;
