import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { PhoneNumberUtil } from 'google-libphonenumber';
import {
  faFile,
  faFileImage,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAudio,
  faFileVideo,
  faFileArchive,
  faStickyNote,
  faSearch,
  faCheckCircle,
  faRocket,
  faExclamationCircle,
  faPenAlt,
  faPlusCircle,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

dayjs.extend(duration);

export const isIterableArray = array => Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key));
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match(
    '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
  );
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const themeColors = {
  primary: '#2c7be5',
  secondary: '#748194',
  success: '#00d27a',
  info: '#27bcfd',
  warning: '#f5803e',
  danger: '#e63757',
  light: '#f9fafd',
  dark: '#0b1727'
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--cubeNet-${name}`).trim();
};

//===============================

// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});
//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);
export const getSubtotal = items =>
  items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) =>
  total - total * (discount / 100);

export const getProductsQuantity = products =>
  products.reduce((acc, product) => product.quantity + acc, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const camelize = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = str => {
  return str.toLowerCase().replaceAll(' ', '-');
};

//routes helper

export const flatRoutes = childrens => {
  const allChilds = [];

  const flatChild = childrens => {
    childrens.forEach(child => {
      if (child.children) {
        flatChild(child.children);
      } else {
        allChilds.push(child);
      }
    });
  };
  flatChild(childrens);

  return allChilds;
};

export const getFlatRoutes = children =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children)
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val]
        };
      }
    },
    { unTitled: [] }
  );

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const reactBootstrapDocsUrl = 'https://react-bootstrap.github.io';

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const tooltipFormatter = params => {
  let tooltipItem = ``;
  params.forEach(el => {
    tooltipItem =
      tooltipItem +
      `<div class='ms-1'> 
        <h6 class="text-700"><span class="fas fa-circle me-1 fs--2" style="color:${
          el.borderColor ? el.borderColor : el.color
        }"></span>
          ${el.seriesName} : ${
        typeof el.value === 'object' ? el.value[1] : el.value
      }
        </h6>
      </div>`;
  });
  return `<div>
            <p class='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format('MMMM DD')
                  : params[0].axisValue
              }
            </p>
            ${tooltipItem}
          </div>`;
};

export const addIdField = items => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item
  }));
};

// get file size

export const getSize = size => {
  if (size < 1024) {
    return `${size} Byte`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};

/* Get A Random Number */
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

/* get Dates between */

export const getDates = (
  startDate,
  endDate,
  interval = 1000 * 60 * 60 * 24
) => {
  const duration = endDate - startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};

/* Get Past Dates */
export const getPastDates = duration => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};

// Add id to items in array
export const addId = items =>
  items.map((item, index) => ({
    id: index + 1,
    ...item
  }));

//
export const getTimeDuration = (startDate, endDate, format = '') => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};

// Get Percentage
export const getPercentage = (number, percent) => {
  return (Number(number) / 100) * Number(percent);
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

// Slugify text
export const slugifyText = str =>
  str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');

export const getDeviceId = () => {
  let navigator_info = window.navigator;
  let screen_info = window.screen;
  let uid = ''; //navigator_info.mimeTypes.length.toString() || ''; deprecated
  uid += navigator_info.userAgent.replace(/\D+/g, '');
  //uid += navigator_info.plugins.length; deprecated
  uid += screen_info.height || '';
  uid += screen_info.width || '';
  uid += screen_info.pixelDepth || '';
  return uid;
};

export const generateRandomPassword = length => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=';
  let password = '';
  for (let i = 0; i < (length || 12); i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};

export const copyTextToClipboard = text => {
  try {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    console.log('Text copied to clipboard');
    return true;
  } catch (err) {
    console.error('Failed to copy text: ', err);
    return false;
  }
};

export const validateEmail = email => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const validatePhoneNumber = (phoneNumber, regionCode) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      regionCode
    );
    const isValid = phoneUtil.isValidNumber(parsedPhoneNumber);
    //console.log(`Phone number is valid: ${isValid}`);
    return isValid;
  } catch (e) {
    //console.error(`Error parsing phone number: ${e}`);
    return false;
  }
};

export const defaultDateFormat = 'YYYY-HH-MM hh:mm A';

export const isPasswordComplex = password => {
  const regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  return regex.test(password);
};

export const toApiFileUrl = path => {
  if (!path) {
    return '';
  }
  if (path?.startsWith('/')) {
    return `${process.env.REACT_APP_FILES_URL}${path}`;
  }
  return `${process.env.REACT_APP_FILES_URL}/${path}`;
};

export const toClientUrl = path => {
  if (!path) {
    return '';
  }
  if (path?.startsWith('/')) {
    return `${process.env.REACT_APP_URL}${path}`;
  }
  return `${process.env.REACT_APP_URL}/${path}`;
};

export const truncateString = (str, maxLength) => {
  if (!str) {
    return '';
  }
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  } else {
    return str;
  }
};

// const ObjectId = (
//   m = Math,
//   d = Date,
//   h = 16,
//   s = s => m.floor(s).toString(h)
// ) => s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

export const sort = (arr, property, sortOrder) => {
  const sortedArray = arr.sort((a, b) => {
    let aValue = a;
    let bValue = b;
    const properties = property.split('.');
    for (let i = 0; i < properties.length; i++) {
      aValue = aValue[properties[i]];
      bValue = bValue[properties[i]];
    }
    if (sortOrder === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else if (sortOrder === 'desc') {
      return aValue > bValue ? -1 : 1;
    }
  });
  return sortedArray;
};

export const formatDuration = (duration, t) => {
  if (!duration) {
    return `0`;
  }
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const remainingSeconds = Math.floor(duration % 60);

  let hoursText = '';
  let minutesText = '';
  let secondsText = '';

  if (hours) {
    hoursText = `${hours} ${t('common:labels.durationSymbols.hour')}`;
  }
  if (minutes) {
    minutesText = `${minutes} ${t('common:labels.durationSymbols.minute')}`;
  }
  if (remainingSeconds) {
    secondsText = `${remainingSeconds} ${t(
      'common:labels.durationSymbols.second'
    )}`;
  }

  return `${hoursText || ''} ${minutesText || ''} ${secondsText || ''}`;
};

export const getElementPosition = elementRef => {
  if (elementRef?.current) {
    const data = elementRef.current.getBoundingClientRect();
    return data;
  }
  return { left: 0, top: 0, width: 0, height: 0, x: 0, y: 0 };
};

export const removeArrayDuplicates = (arr, property) => {
  return arr.filter((item, index) => {
    return index === arr.findIndex(obj => obj[property] === item[property]);
  });
};

export const getFileIcon = fileType => {
  let icon;

  switch (fileType) {
    case 'pdf':
      icon = faFilePdf;
      break;
    case 'doc':
    case 'docx':
      icon = faFileWord;
      break;
    case 'xls':
    case 'xlsx':
      icon = faFileExcel;
      break;
    case 'ppt':
    case 'pptx':
      icon = faFilePowerpoint;
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
    case 'svg':
    case 'webp':
      icon = faFileImage;
      break;
    case 'mp3':
    case 'wav':
    case 'ogg':
      icon = faFileAudio;
      break;
    case 'mp4':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'flv':
      icon = faFileVideo;
      break;
    case 'zip':
    case 'rar':
    case '7z':
    case 'tar':
      icon = faFileArchive;
      break;
    default:
      icon = faFile;
      break;
  }

  return icon;
};

// Function to get file extension from URL
export const getFileExtensionFromUrl = url => {
  const fileName = url.substring(url.lastIndexOf('/') + 1);
  const fileExtension = fileName.split('.').pop().toLowerCase();
  return fileExtension;
};
export const getFileNameFromUrl = url => {
  const fileName = url.substring(url.lastIndexOf('/') + 1);
  return fileName;
};
export const getAcceptedFileTypes = () => {
  const acceptedFileTypes = [
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.svg',
    '.mp3',
    '.wav',
    '.ogg',
    '.mp4',
    '.avi',
    '.mov',
    '.wmv',
    '.flv',
    '.zip',
    '.rar',
    '.7z',
    '.tar'
  ];

  return acceptedFileTypes.join(',');
};

export const downloadFile = fileUrl => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getStateIcon = state => {
  if (!state) {
    return faQuestionCircle;
  }
  switch (state.toLowerCase()) {
    case 'draft':
      return faStickyNote;
    case 'onreview':
    case 'inactive':
      return faSearch;
    case 'approved':
    case 'active':
      return faCheckCircle;
    case 'published':
      return faRocket;
    case 'modificationrequested':
      return faExclamationCircle;

    default:
      return faStickyNote;
  }
};

export const getLogIcon = action => {
  switch (action.toLowerCase()) {
    case 'update':
      return faPenAlt;
    case 'create':
      return faPlusCircle;
    default:
      return faPenAlt;
  }
};

export function isHTMLContent(str) {
  const htmlRegex = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>/i;
  return htmlRegex.test(str);
}

export const convertToUniqueName = name => {
  const illegalCharsRegex = /[^\w.-]/g;
  const underscoreRegex = /\s/g;

  // Replace spaces with underscores
  const nameWithUnderscores = name.replace(underscoreRegex, '_');

  // Remove illegal URL characters
  const cleanName = nameWithUnderscores.replace(illegalCharsRegex, '');

  // Generate a unique identifier (timestamp in this example)
  const uniqueIdentifier = Date.now();

  // Combine the clean name and unique identifier
  const uniqueName = `${cleanName}_${uniqueIdentifier}`;

  return uniqueName;
};

export const sumProperty = (arr, property, subProperty) => {
  if (!!subProperty) {
    return arr.reduce(
      (accumulator, obj) => accumulator + obj[property][subProperty],
      0
    );
  }
  return arr.reduce((accumulator, obj) => accumulator + obj[property], 0);
};

export const getRedirectUrl = user => {
  //console.log('getRedirectUrl user', user);
  let url = '/not-found';
  if (!user || !user?.roles?.length) {
    url = '/login';
  }
  let roles = user?.roles;

  if (typeof roles === 'string') {
    roles = [roles.toLowerCase().trim()];
  } else {
    roles = roles.map(r => r.toLowerCase().trim());
  }
  if (roles.includes('stakeholder')) {
    url = '/dashboard';
  }

  if (roles.includes('planner')) {
    url = '/courses';
  }

  if (roles.includes('marketing')) {
    url = '/contents/blogs';
  }

  if (roles.includes('admin')) {
    url = '/dashboard';
  }

  //console.log('url', { roles, url });
  // console.log('getRedirectUrl user', { user, url });
  return url;
};

export const getStateColor = state => {
  switch (state?.toLowerCase()) {
    case 'draft':
      return 'secondary';
    case 'approved':
    case 'active':
      return 'primary';
    case 'inactive':
    case 'rejected':
      return 'danger';

    default:
      return 'info';
  }
};

export const constructDate = ({ date, addDays, addMonths, addYears, iso }) => {
  //console.log('constructDate', { date, addDays, addMonths, addYears, iso });
  let finalDate = new Date();
  let baseDate = new Date();
  if (!!date) {
    baseDate = date;
  }

  if (!!addDays) {
    finalDate = moment(baseDate).add(addDays, 'days');
  }
  if (!!addMonths) {
    finalDate = moment(baseDate).add(addMonths, 'months');
  }
  if (!!addYears) {
    finalDate = moment(baseDate).add(addYears, 'years');
  }
  if (!!iso) {
    finalDate = finalDate.toISOString();
  }

  return finalDate;
};

export function getCategoryColor(category) {
  let color;

  switch (category) {
    case 'business':
      color = '#FF6F61';
      break;
    case 'datavisualization':
      color = '#6B5B95';
      break;
    case 'python':
      color = '#88B04B';
      break;
    case 'deeplearning':
      color = '#F7CAC9';
      break;
    case 'artificialintelligence':
      color = '#92A8D1';
      break;
    case 'rprogramming':
      color = '#955251';
      break;
    case 'datascience':
      color = '#B565A7';
      break;
    case 'tableau':
      color = '#009B77';
      break;
    case 'powerbi':
      color = '#DD4124';
      break;
    case 'excel':
      color = '#D65076';
      break;
    case 'database':
      color = '#45B8AC';
      break;
    case 'statistics':
      color = '#EFC050';
      break;
    case 'careertips':
      color = '#3B3B3B';
      break;
    default:
      color = null;
  }

  return color;
}
