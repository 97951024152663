import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LmsStats from './lms-stats/LmsStats';
import Payments from './payments/Payments';
import CourseEnrollments from './course-enrollments/CourseEnrollments';
import Users from './users/Users';
import Courses from './courses/Courses';
import Revenues from './revenues/Revenues';
import NewUsers from './newUsers/NewUsers';
// import NewStudents from './newStudents/NewStudents';

const Lms = () => {
  return (
    <>
      <LmsStats />
      <Users className="mb-3" />

      <Row className="mb-3 mt-2">
        <Col lg={6}>
          <CourseEnrollments />
        </Col>
        <Col lg={6}>
          <Courses />
        </Col>
      </Row>
      <Payments className="mb-3" />

      <Row className="g-3">
        <Col>
          <Revenues />
        </Col>
      </Row>
      <NewUsers />
      {/* <NewStudents /> */}
    </>
  );
};

export default Lms;
