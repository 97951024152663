import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import CubeNetCloseButton from 'components/common/CubeNetCloseButton';
import { useTranslation } from 'react-i18next';
import SelectComponent from 'components/common/SelectComponent';
import UserService from 'http/UserService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { validateEmail, validatePhoneNumber } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const initFormData = {
  email: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  roles: null
};

const toUserForm = data => {
  return {
    email: data?.email || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    phoneNumber: data?.phoneNumber || '',
    roles: data?.roleNames || []
  };
};

const UserModal = ({ isOpen, onClose, user, roles, refetch }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [formData, setFormData] = useState(null);
  const [errors, setErrors] = useState(null);

  const editDisabled = !!user?.id;

  useEffect(() => {
    if (!!user && roles?.length) {
      let usedRoles = user.roleNames;
      if (typeof usedRoles === 'string') {
        usedRoles = [usedRoles];
      }
      usedRoles = usedRoles.map(r => r.name.toLowerCase().trim());
      const userRoles = roles.filter(r =>
        usedRoles.includes(r.name.toLowerCase().trim())
      );
      // console.log('usedRoles', usedRoles);
      // console.log('userRoles', userRoles);
      let userData = {
        ...toUserForm(user),
        roles: userRoles
      };
      setFormData(userData);
    }
    if (!user) {
      setFormData(initFormData);
    }
  }, [roles, user]);

  const handleChange = (name, value) => {
    if (name === 'roles') {
      setFormData({ ...formData, roles: value });
    }
    if (name === 'phoneNumber') {
      if (!!value && isNaN(value)) {
        return;
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const formDataJson = JSON.stringify({ ...formData });
    const userDataJson = JSON.stringify({ ...user });
    if (formDataJson !== userDataJson) {
      setHasChange(true);
    } else {
      setHasChange(false);
    }
  }, [formData]);

  const handleSubmit = () => {
    const hasErrors = handleValidateFormData(formData);
    if (hasErrors) {
      return;
    }
    setLoading(true);
    const data = {
      ...formData,
      emailAddress: formData.email,
      roles: formData.roles.map(role => role.id)
    };
    //console.log('data', data);
    if (!!user?.id) {
      const selectedIds = formData.roles.map(role => role.id);
      UserService.updateRoles({ UserId: user.id, NewRoles: selectedIds })
        .then(response => {
          if (response) {
            toast.success(t('user:message.updateRolesSuccess'));
            refetch && refetch();
            onClose();
          } else {
            toast.error(getErrorMessage(t, response), {
              theme: 'colored',
              autoClose: false
            });
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    } else {
      UserService.create(data)
        .then(response => {
          if (response) {
            toast.success(t('user:message.createUserSuccess'));
            refetch && refetch();
            onClose();
          } else {
            toast.error(getErrorMessage(t, response), {
              theme: 'colored',
              autoClose: false
            });
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    }
  };

  const handleValidateFormData = data => {
    let errorsObj = null;
    Object.entries(data).map(([key, value]) => {
      if (key === 'metadata') {
        return;
      }
      if (key === 'email' && !!value) {
        if (!validateEmail(value)) {
          errorsObj = {
            ...errorsObj,
            [key]: {
              invalid: true,
              message: t('user:validation.invalidEmail')
            }
          };
          return;
        }
      }
      if (key === 'phoneNumber' && !!value) {
        if (!validatePhoneNumber(`+${value}`)) {
          errorsObj = {
            ...errorsObj,
            [key]: {
              invalid: true,
              message: t('user:validation.invalidPhoneNumber')
            }
          };
          return;
        }
      }
      if (!value) {
        errorsObj = {
          ...errorsObj,
          [key]: {
            invalid: true,
            message: t('common:validation.fieldIsRequired')
          }
        };
        return;
      } else {
        errorsObj = { ...errorsObj, [key]: null };
        return;
      }
    });
    setErrors(errorsObj);
    let inError = false;
    if (Object.values(errorsObj).some(val => !!val)) {
      inError = true;
    }
    //console.log('errorsObj', errorsObj);
    return inError;
  };

  return (
    <Modal
      show={isOpen}
      onHide={() => onClose(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t(
            `user:message.${
              !!user ? 'userEditModalHeader' : 'userCreateModalHeader'
            }`,
            { email: user?.fullName }
          )}
        </Modal.Title>
        <CubeNetCloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => onClose(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group
            as={Col}
            xs="12"
            //lg="4"
            className="mb-3"
            controlId="userForm.email"
          >
            <Form.Label>{t('user:labels.email')}</Form.Label>
            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                placeholder={t('user:labels.email')}
                onChange={e => handleChange('email', e.target.value)}
                isInvalid={errors?.email?.invalid}
                value={formData?.email || formData?.email || ''}
                disabled={editDisabled}
              />
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="user-email-tooltip">
                    {!!(formData?.email || formData?.email) &&
                      t('user:notes.email')}
                  </Tooltip>
                }
              >
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </InputGroup.Text>
              </OverlayTrigger>

              {errors?.email?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.email?.message}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            lg="4"
            className="mb-3"
            controlId="userForm.firstName"
          >
            <Form.Label>{t('user:labels.firstName')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('user:labels.firstName')}
              onChange={e => handleChange('firstName', e.target.value)}
              isInvalid={errors?.firstName?.invalid}
              value={formData?.firstName || ''}
              disabled={editDisabled}
            />
            {errors?.firstName?.message && (
              <Form.Control.Feedback type="invalid">
                {errors?.firstName?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            lg="4"
            className="mb-3"
            controlId="userForm.lastName"
          >
            <Form.Label>{t('user:labels.lastName')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('user:labels.lastName')}
              onChange={e => handleChange('lastName', e.target.value)}
              isInvalid={errors?.lastName?.invalid}
              value={formData?.lastName || ''}
              disabled={editDisabled}
            />
            {errors?.lastName?.message && (
              <Form.Control.Feedback type="invalid">
                {errors?.lastName?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            lg="4"
            className="mb-3"
            controlId="userForm.phoneNumber"
          >
            <Form.Label>{t('user:labels.phoneNumber')}</Form.Label>

            <InputGroup className="mb-2">
              <Form.Control
                type="text"
                placeholder="123xxxxxxxxx"
                onChange={e => handleChange('phoneNumber', e.target.value)}
                isInvalid={errors?.phoneNumber?.invalid}
                value={formData?.phoneNumber || ''}
                disabled={editDisabled}
                dir="ltr"
              />

              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="user-phoneNumber-tooltip">
                    {t('user:tooltips.phoneNumber')}
                  </Tooltip>
                }
              >
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </InputGroup.Text>
              </OverlayTrigger>
              {errors?.phoneNumber?.message && (
                <Form.Control.Feedback type="invalid">
                  {errors?.phoneNumber?.message}
                </Form.Control.Feedback>
              )}
            </InputGroup>
          </Form.Group>
          <Form.Group
            as={Col}
            xs="12"
            lg="8"
            className="mb-3"
            controlId="userForm.phoneNumber"
          >
            <Form.Label>{t('user:labels.roles')}</Form.Label>
            <SelectComponent
              options={roles}
              placeholder={t('user:labels.roles')}
              isMulti
              classNamePrefix="react-select"
              value={formData?.roles || []}
              onChange={value => handleChange('roles', value)}
              //getOptionLabel={option => option.label}
              //getOptionValue={option => option.id}
              errorText={errors?.roles?.message || ''}
              invalid={!!errors?.roles?.invalid}
            />
          </Form.Group>
          {formData?.roles?.map(role => {
            return (
              <p
                className="break-spaces fs--1"
                key={role.id}
                dangerouslySetInnerHTML={{
                  __html: t(`user:notes.roles.${role.key.toLowerCase().trim()}`)
                }}
              ></p>
            );
          })}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            setFormData(null);
            onClose(false);
          }}
          variant="secondary"
          disabled={loading}
        >
          {t('common:button.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          variant={'primary'}
          disabled={loading || !hasChange}
        >
          {!loading && t('common:button.save')}
          {loading && <Spinner animation="border" role="status" size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserModal;
