import React, { useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CubeNetCloseButton from 'components/common/CubeNetCloseButton';
import Flex from 'components/common/Flex';
import { copyTextToClipboard, generateRandomPassword } from 'helpers/utils';
import UserService from 'http/UserService';
import { getErrorMessage } from 'http/utils';
import { toast } from 'react-toastify';

const ChangePasswordModal = ({ user, isOpen, onClose }) => {
  const { t } = useTranslation();
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const generatePassword = () => {
    setGeneratedPassword(generateRandomPassword(12));
  };
  const copyPassword = () => {
    if (copyTextToClipboard(generatedPassword)) {
      toast.success(t('common:message.copyToClipboardSuccess'), {
        theme: 'colored'
      });
    } else {
      toast.error(t('common:error.errorCopyingTextToClipBoard'), {
        theme: 'colored',
        autoClose: false
      });
    }
    return;
  };
  const changePassword = () => {
    if (generatedPassword?.length) {
      setLoading(true);
      UserService.changePassword({
        UserId: user.id,
        NewPassword: generatedPassword
      })
        .then(response => {
          if (response) {
            toast.success(t('user:message.changePasswordSuccess'));
            onClose();
          } else {
            toast.error(getErrorMessage(t, response), {
              theme: 'colored',
              autoClose: false
            });
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    }
  };
  return (
    <Modal
      show={isOpen}
      onHide={() => onClose(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('user:message.changePasswordModalHeader', {
            username: user.fullName
          })}
        </Modal.Title>
        <CubeNetCloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => onClose(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <Flex alignItems="center" justifyContent="start" className="w-100 mb-3">
          <Button variant="success" size="sm" onClick={generatePassword}>
            {t('common:button.generate')}
          </Button>
          <Form.Control
            onChange={() => {}}
            value={generatedPassword}
            className="m-0 w-100"
            name="changePassword-admin"
            label=""
          />
          <Button variant="primary" size="sm" onClick={copyPassword}>
            {t('common:button.copy')}
          </Button>
        </Flex>
        <small className="mt-3">
          {t('user:message.changePasswordModalMessage')}
        </small>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => onClose(false)}
          variant="secondary"
          disabled={loading}
        >
          {t('common:button.cancel')}
        </Button>
        <Button onClick={changePassword} variant={'primary'} disabled={loading}>
          {!loading && t('common:button.save')}
          {loading && <Spinner animation="border" role="status" size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
