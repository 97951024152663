import React, { useState, useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import CubeNetCardHeader from 'components/common/CubeNetCardHeader';
import CoursesChart from './CoursesChart';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import StatisticsService from 'http/StatisticsService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { constructDate } from 'helpers/utils';
const initFilter = {
  type: 'daily',
  fromDate: constructDate({ date: new Date(), addDays: -7, iso: true }),
  toDate: new Date().toISOString()
};

function Courses() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [courses, setCourses] = useState([]);
  const [filters, setFilters] = useState(initFilter);
  const [categories, setCategories] = useState([]);
  const [chartDays, setChartDays] = useState(7);

  const {
    //refetch: fetchUsersStats,
    isLoading: enrollmentsStatsLoading,
    data: enrollmentsStatsData,
    error: enrollmentsStatsError
  } = useQuery(
    ['CoursesStats', filters],
    () => StatisticsService.coursesOverPeriod(filters),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: true
    }
  );

  useEffect(() => {
    if (
      enrollmentsStatsData &&
      !enrollmentsStatsLoading &&
      !enrollmentsStatsError
    ) {
      if (enrollmentsStatsData?.errors?.length) {
        toast.error(getErrorMessage(t, enrollmentsStatsData?.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      if (!!enrollmentsStatsData?.payload) {
        setCategories(enrollmentsStatsData?.payload?.categoriesList);
        setCourses(enrollmentsStatsData?.payload?.coursePeriodList);
      }
    }

    if (enrollmentsStatsError) {
      toast.error(getErrorMessage(t, enrollmentsStatsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [enrollmentsStatsLoading, enrollmentsStatsData, enrollmentsStatsError]);

  useEffect(() => {
    let newFilters = {
      ...filters
    };
    switch (chartDays) {
      case 7:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'daily',
          fromDate: constructDate({ date: new Date(), addDays: -7, iso: true })
        };
        break;
      case 30:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'daily',
          fromDate: constructDate({
            date: new Date(),
            addMonths: -1,
            iso: true
          })
        };
        break;
      case 365:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'monthly',
          fromDate: constructDate({ date: new Date(), addYears: -1, iso: true })
        };
        break;
      default:
        break;
    }
    //console.log('newFilters', newFilters);
    setFilters(newFilters);
  }, [chartDays]);

  return (
    <Card className="h-100 ">
      <CubeNetCardHeader
        title={t('dashboard:labels.courses')}
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Form.Select
            size="sm"
            className="me-2"
            onChange={e => setChartDays(Number(e.target.value))}
          >
            <option value={'7'}>{t('common:labels.last7Days')}</option>
            <option value={'30'}>{t('common:labels.lastMonth')}</option>
            <option value={'365'}>{t('common:labels.lastYear')}</option>
          </Form.Select>
        }
      />
      <Card.Body className="">
        <CoursesChart
          data={courses}
          categories={categories}
          currentLanguage={currentLanguage}
          type={filters.type}
        />
      </Card.Body>
    </Card>
  );
}

export default Courses;
