import React, { useEffect } from 'react';
import IconButton from './IconButton';
import { utils, writeFile } from 'xlsx';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';

const ExportExcelButton = ({ loading, t, data, headers, onClick }) => {
  useEffect(() => {
    if (data?.length && headers?.length && !loading) {
      handleExport(data, headers);
    }
  }, [data, loading]);

  const handleExport = (data, headers) => {
    const rows = data;
    const worksheet = utils.aoa_to_sheet([headers, ...rows]);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, t('user:allUsers'));
    writeFile(workbook, `${t('user:allUsers')}.xlsx`);
  };

  if (loading) {
    return (
      <Button size="sm" disabled variant="light">
        <Spinner variant="spinner" size="sm" />
      </Button>
    );
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="user-export-tooltip">{t('common:button.export')}</Tooltip>
      }
    >
      <span>
        <IconButton
          variant="cubeNet-default"
          size="sm"
          icon="external-link-alt"
          //transform="shrink-3"
          className="mx-2"
          iconAlign="middle"
          onClick={() => onClick()}
        >
          <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
            {t('common:button.export')}
          </span>
        </IconButton>
      </span>
    </OverlayTrigger>
  );
};

export default ExportExcelButton;
